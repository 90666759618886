export const firebaseConfig = {
  apiKey: "AIzaSyBHHUm8jU8r101OehnBYit2f0eG8CO53Ks",
  authDomain: "trackmyallergy.firebaseapp.com",
  databaseURL: "https://trackmyallergy.firebaseio.com",
  projectId: "trackmyallergy",
  storageBucket: "trackmyallergy.appspot.com",
  messagingSenderId: "1063371669384",
  appId: "1:1063371669384:web:34aec930c45560ecc1043d",
  measurementId: "G-8GNLQFJKC4",
};
